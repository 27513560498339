require('./gascrolldepth.custom');

import Analytics from './analytics'

window.analytics = window.analytics || new Analytics()

window.gaTrackEvent = analytics.getTrackEvent({
  'eventNonInteraction': false
});

window.clickEventElements = window.clickEventElements || [];
window.pageViewEventElements = window.pageViewEventElements || [];

/**
 * Track click event for specified elements. Use the element's text as the
 * default eventLabel. Allow custom overrides.
 * @param  {Event}  e
 * @return {void}
 */
window.gaClickEvent = function(e) {
  var data = JSON.parse(this.getAttribute('data-ga-click-event') || '{}');

  if (!data.eventCategory) {
    return false;
  }

  gaTrackEvent({
    'eventCategory' : data.eventCategory,
    'eventAction'   : data.eventAction || 'Clicked',
    'eventLabel'    : data.eventLabel  || this.textContent.trim(),
    'eventValue'    : data.eventValue  || 0
  });

  // DEBUG
  // e.preventDefault();
}

window.gaDoubleClickEvent = function(e) {
  var data = JSON.parse(this.getAttribute('data-ga-dblclick-event') || '{}');

  if (!data.eventCategory) {
    return false;
  }

  gaTrackEvent({
    'eventCategory' : data.eventCategory,
    'eventAction'   : data.eventAction || 'Double Clicked',
    'eventLabel'    : data.eventLabel  || this.textContent.trim(),
    'eventValue'    : data.eventValue  || 0
  });

}

/**
 * Track click event for specified elements. Use the element's text as the
 * default eventLabel. Allow custom overrides.
 * @param  {Event}  e
 * @return {void}
 */
window.gaVirtualPageview = function(e) {
  var data = this.getAttribute('data-ga-virtual-pageview');

  gaTrackEvent({
    'event': 'GAVirtualPageview',
    'eventCategory': data
  });

  // DEBUG
  // e.preventDefault();
}


function resetClickHandlers({ addDefaultClickHandlers = true } = {}) {

  if (!addDefaultClickHandlers) {
    return;
  }

  document.querySelectorAll('[data-ga-click-event]').forEach((el) => {
    el.removeEventListener('click', window.gaClickEvent)
    el.addEventListener('click', window.gaClickEvent)
  })

  document.querySelectorAll('[data-ga-dblclick-event]').forEach((el) => {
    el.removeEventListener('dblclick', window.gaDoubleClickEvent)
    el.addEventListener('dblclick', window.gaDoubleClickEvent)
  })

  document.querySelectorAll('[data-ga-virtual-pageview]').forEach((el) => {
    el.removeEventListener('click', window.gaVirtualPageview)
    el.addEventListener('click', window.gaVirtualPageview)
  })
}

window.resetAnalyticsClickHandlers = resetClickHandlers;


export const initAnalytics = function({ addDefaultClickHandlers = true } = {}) {

  resetClickHandlers(addDefaultClickHandlers)

  return window.analytics
}

export const getTrackEvent = function(defaults) {
  return window.analytics.getTrackEvent.call(analytics, defaults);
}

export const setScrollDepthElements = function() {
  console.warn('called setScrollDepthElements. please implement')
}

export const addScrollDepthElements = function() {
  console.warn('called addScrollDepthElements. please implement')
}

export const clearScrollDepthElements = function() {
  console.warn('called clearScrollDepthElements. please implement')
}
